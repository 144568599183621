/* react-simple-tree-menu
  non è possibile importare files al di fuori della cartella src ( dipende da come è impostato il processo di build )
  quindi lo copio qui dentro. Path originale /node_modules/react-simple-tree-menu/dist/main.css
*/
.rstm-toggle-icon {
   display: inline-block;
}
.rstm-toggle-icon-symbol {
   width: 2rem;
   height: 2rem;
   text-align: center;
   line-height: 2rem;
}

.rstm-tree-item-group {
   list-style-type: none;
   padding-left: 0;
   border-top: 1px solid #ccc;
   text-align: left;
   width: 100%;
}
.rstm-tree-item--focused {
   box-shadow: 0 0 5px 0 #222;
   z-index: 999;
}
.rstm-search {
   padding: 1rem 1.5rem;
   border: none;
   width: 100%;
}
.rstm-tree-item--active {
   color: white;
   background: #59648b;
   border-bottom: none;
}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-bullet {
   text-align: center;
   -webkit-box-flex: 0;
   -ms-flex: 0 0 10px;
   flex: 0 0 10px;
   margin-right: 10px;
}
.rstm-toggle-icon-symbol {
   width: 1.8rem;
   height: 1.8rem;
   text-align: center;
   line-height: 1.8rem;
   margin-right: 0px;
}
.rstm-tree-item-group > .rstm-tree-item {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-flex: 1;
   -ms-flex-positive: 1;
   flex-grow: 1;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   padding: 0.55rem 1.55rem;
   cursor: pointer;
   -webkit-transition: all 0.3s;
   transition: all 0.3s;
   color: #444;
}
.rstm-tree-item--active {
   color: #1e2e62;
   background: #fbfbfb;
   border-bottom: none;
}
.rstm-tree-item--focused {
   box-shadow: 0 0 1px 0 #b8b9ae;
}

/*** choose a users input ***/
.form-control-typeahead {
   display: block;
   width: 100%;
}
.form-control-typeahead input {
   height: 34px;
   padding: 5px 12px;
   font-size: 14px;
   /*
   line-height: 1.42857143;
   display: block;
   width: 100%;
   color: #555;
   background-color: #fff;
   background-image: none;
   border: 1px solid #ccc;
   border-radius: 4px;*/
}

.form-control-typeahead .rbt-input {
   height: auto;
   padding: 5px 12px;
   font-size: 15px;
   line-height: 2.4;
}
.form-control-typeahead .rbt-input-hint-container {
   /*display: inline-block;*/
}
.form-control-typeahead .rbt-menu {
   margin: 0;
}

.form-control-typeahead .rbt-menu li {
   padding: 0.2em 0;
}
.form-control:focus {
   border-color: #1e2e62;
}

/*! CSS Used from: Embedded */
.rbt-close {
   z-index: 1;
}
.rbt-token {
   background-color: rgba(110, 197, 43, 0.25);
   border: 0;
   border-radius: 2px;
   color: #336633;
   display: inline-block;
   line-height: 1em;
   margin: 0 3px 3px 0;
   padding: 4px 7px;
   position: relative;
}
.rbt-token-removeable {
   cursor: pointer;
   padding-right: 21px;
}
.rbt-token .rbt-token-remove-button {
   bottom: 0;
   color: inherit;
   font-size: inherit;
   font-weight: normal;
   opacity: 1;
   outline: none;
   padding: 3px 7px;
   position: absolute;
   right: 0;
   text-shadow: none;
   top: -2px;
}
